<template>
  <div class="benvenuto">
    <h1>Benvenuto, questa è la Data Platform ...</h1>
    <v-img :src="`${publicPath}img/ubisell-logo.png`" max-height="50vh" contain eager/>
    <h2>Abilita la licenza per accedere ai tuoi dati online</h2>
<!--     <div>mobileToken {{mobileToken}}</div>
    <div>token {{token}}</div>
    <div>licPath {{licPath}}</div>
    <div>serviceUrl {{serviceUrl}}</div>
    <div>username {{username}}</div>
    <div>password {{password}}</div>
    <div>device {{device}}</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL
/*       mobileToken: 'xxx',
      token: 'xxx',
      licPath: process.env.VUE_APP_service_licenze,
      serviceUrl: process.env.VUE_APP_serviceUrl,
      username: process.env.VUE_APP_serviceUsername,
      password: process.env.VUE_APP_servicePassword,
      device: null */
    }
  }
/*   mounted() {
    this.mobileToken = this.$store.getters['mobile/mobileToken']
    this.token = this.$store.getters['mobile/token']
    this.device = this.$store.getters['mobile/mobileToken']
  } */
}
</script>

<style scoped>
  .benvenuto {
    display: block;
    width: 100vw;
    height: 98vh;
    text-align: center;
    box-sizing: border-box;
    padding-top: 10vh;
  }
</style>
